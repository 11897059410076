html, body {
  margin: 0;
  padding: 0;
  font-family: 'Avenir Next', sans-serif;
}

.min-height-100vh {
  min-height: 100vh;
}

.min-height-100vh-webkit {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.magic-link {
  border: 2px dashed #B4BFCF;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px 16px;
  margin: 20px;
  max-width: 400px;
  text-align: center;
}

.magic-link a {
  color: #605FB2 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
}

/* @font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'), url(./assets/fonts/AvenirNext-Regular.ttf) format('ttf');
} */